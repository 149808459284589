import "./NavBar.css";

import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";

import logo from "./xm_new.png";

function NavBar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [contentMarginTop, setContentMarginTop] = useState(0);

  const location = useLocation(); // Get current location

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    setContentMarginTop(!isMobileMenuOpen ? 370 : 0); // Adjust the margin-top
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsMobileMenuOpen(false);
        setContentMarginTop(0);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-logo">
          <Link to="/"><img src={logo} alt="logo" /></Link>
        </div>
        <ul className={`navbar-links ${isMobileMenuOpen ? "active" : ""}`}>
          <Link className="li-nav-link" to="/solutions"> 
            <li className={`navbar-item ${location.pathname === "/solutions" ? "active" : ""}`}>
              Solutions
            </li>
          </Link>
          <Link className="li-nav-link" to="/3d"> 
            <li className={`navbar-item ${location.pathname === "/3d" ? "active" : ""}`}>
              3D Configurators
            </li>
          </Link>
          <Link className="li-nav-link" to="/ar"> 
            <li className={`navbar-item ${location.pathname === "/ar" ? "active" : ""}`}>
              Augmented Reality
            </li>
          </Link>
          <Link className="li-nav-link" to="/pricing"> 
            <li className={`navbar-item ${location.pathname === "/pricing" ? "active" : ""}`}>
              Pricing
            </li>
          </Link>
          <Link className="li-nav-link" to="/faq"> 
            <li className={`navbar-item ${location.pathname === "/faq" ? "active" : ""}`}>
              FAQ
            </li>
          </Link>
        </ul>

        <div className="navbar-right">
          <Link to="/book-demo">
            <button className="nav-book-demo">Book Demo</button>
          </Link>
          <div
            className="mobile-menu-icon"
            onClick={toggleMobileMenu}
            aria-label="Toggle mobile menu"
          >
            ☰
          </div>
        </div>
      </nav>

      <div style={{ marginTop: contentMarginTop + 'px' }}>
        {/* This is where your page content will go */}
      </div>
    </>
  );
}

export default NavBar;
