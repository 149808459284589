import "./FAQ.css";

import React, { useState } from "react";

import Footer from "../Footer/Footer";

export function FAQ() {
  const [expandedIndex, setExpandedIndex] = useState(null);

  // FAQ items array
  const faqItems = [
    {
      question: "What is a 3D configurator and how can it help me?",
      answer:
        "A 3D configurator allows you to interactively customize products in real-time, changing features like colors, materials, and sizes. This gives you a clear visual representation of the final product before purchasing, ensuring that it meets your exact preferences and reducing the risk of returns."
    },
    {
      question: "How does augmented reality (AR) improve my shopping experience?",
      answer:
        "AR enhances your shopping experience by letting you visualize products in your real-world environment through your smartphone camera. You can see how furniture fits in your space, try on virtual accessories, or even check how a new car would look in your driveway, making your decisions more informed and confident."
    },
    {
      question: "Do I need any special software to use the 3D configurator or AR features?",
      answer:
        "No, you don’t need any special software. Our 3D configurator works seamlessly in your web browser, and AR features can be accessed directly through AR-compatible smartphones. This makes it easy for you to explore and customize products without any extra steps."
    },
    {
      question: "Can I see my customized product in AR before making a purchase?",
      answer:
        "Yes! Once you've customized a product using the 3D configurator, you can use AR to see exactly how your personalized product will look in your home or environment. This helps you make confident purchasing decisions by visualizing the product in real-time before buying."
    },
    {
      question: "How accurate are the 3D models and AR visualizations?",
      answer:
        "Our 3D models and AR visualizations are designed to be highly accurate, providing a true-to-life representation of the product, including size, texture, and color. However, there may be slight variations depending on lighting and your device’s screen settings."
    },
    {
      question: "What industries commonly use 3D configurators and AR?",
      answer:
        "3D configurators and AR are used in a variety of industries including e-commerce, automotive, fashion, real estate, and furniture. These tools enhance the shopping experience by offering personalized, interactive, and immersive product views that help customers make better buying decisions."
    },
    {
      question: "How does using a 3D configurator benefit me as a customer?",
      answer:
        "As a customer, a 3D configurator allows you to personalize products to suit your tastes. Whether you're designing a car, choosing furniture, or selecting clothing, it lets you visualize all your options before committing, ensuring you get exactly what you want."
    },
    {
      question: "How do 3D configurators help with product customization?",
      answer:
        "3D configurators allow you to tailor products based on your specific preferences, such as changing colors, materials, or features. This gives you complete control over the design process, ensuring the final product matches your style and needs exactly as you envisioned."
    }
  ];

  // Toggle function to show/hide answers
  const toggleExpansion = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <>
      <div className="faq-container">
        <h2 className="faq-title">
          Frequently asked questions about XM3DView 3D configurators & AR
        </h2>
        {faqItems.map((item, index) => (
          <div className="faq-item" key={index}>
            <h3 className="faq-question" onClick={() => toggleExpansion(index)}>
              {item.question}
              <span className="faq-plus-minus">
                {expandedIndex === index ? "-" : "+"}
              </span>
            </h3>
            {expandedIndex === index && (
              <div className="faq-answer">
                <p className="p-data">{item.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
      <Footer />
    </>
  );
}
