import './Price.css';

import Footer from '../Footer/Footer';
import {Link} from 'react-router-dom';
import React from 'react';

const Price = () => {
  return (
    <>
      <div className="pricing-section-container">
        <h1 className="pricing-heading">Flexible 3D Configurator Plans</h1>
        <h2 className="pricing-title">
          Start your <span className="pricing-highlight">15-day free trial</span> and unlock immersive 3D experiences.
        </h2>

        <div className="pricing-cards">
          {/* Standard Plan */}
          <div className="pricing-card">
            <h3 className="card-title">
              <span className="card-label">STANDARD</span> for Small Businesses
            </h3>
            <h4 className="card-price">
              $75 - $150 <span className="card-price-monthly">per product, per month (varies by 3D model complexity)</span>
            </h4>
            <p className="card-description">Designed for individual product experiences and small businesses to showcase their 3D models with customizable materials.</p>
            <ul className="card-features">
              <li className="feature-item">Includes up to 10 materials per product (colors, textures, etc.)</li>
              <li className="feature-item">Additional materials: $20 for 10 items or $10 for 5 items</li>
              <li className="feature-item">Augmented Reality (AR) support for mobile and web</li>
              <li className="feature-item">High-quality rendering with interactive controls</li>
              <li className="feature-item">Unlimited model exports (usdz, glb, gltf formats)</li>
              <li className="feature-item">Basic animation support (e.g., rotating parts, simple interactions)</li>
              <li className="feature-item">Version history with 30-day retention</li>
              <li className="feature-item">14-day free trial to test all features</li>
              <li className="feature-item">Support via chat and email</li>
            </ul>
          <Link to="/book-demo"> <button className="card-button">Start 14-Day Trial</button></Link>
          </div>

          {/* Professional Plan */}
          <div className="pricing-card">
            <h3 className="card-title">
              <span className="card-label">PROFESSIONAL</span> for Growing Teams
            </h3>
            <h4 className="card-price">
              $199 - $250 <span className="card-price-monthly">per product, per month</span>
            </h4>
            <p className="card-description">Perfect for brands expanding their product lineup with customizable and high-quality 3D visualizations.</p>
            <ul className="card-features">
              <li className="feature-item">Up to 20 materials per product</li>
              <li className="feature-item">Advanced animations (e.g., layered parts, configurator changes)</li>
              <li className="feature-item">Increased AR functionality and support across devices</li>
              <li className="feature-item">Premium rendering quality with enhanced visuals</li>
              <li className="feature-item">Customizable light settings for accurate scene control</li>
              <li className="feature-item">Integrate API and event-based custom interactions</li>
              <li className="feature-item">Project history and version tracking for 60 days</li>
              <li className="feature-item">Priority support and onboarding assistance</li>
            </ul>
            <Link to="/book-demo"><button className="card-button">Start 14-Day Trial</button></Link>
          </div>

          {/* Enterprise Plan */}
          <div className="pricing-card">
            <h3 className="card-title">
              <span className="card-label">ENTERPRISE</span> for Large-Scale Deployments
            </h3>
            <h4 className="card-price">Custom Pricing Available</h4>
            <p className="card-description">Advanced customization and integration for enterprises with expansive 3D configurator requirements.</p>
            <ul className="card-features">
              <li className="feature-item">Unlimited products and projects with team-wide access</li>
              <li className="feature-item">Custom materials, configurations, and real-time changes</li>
              <li className="feature-item">Team access control with role-based permissions</li>
              <li className="feature-item">Integration with SSO/SAML and custom security features</li>
              <li className="feature-item">Dedicated server instances with CDN support and fast load times</li>
              <li className="feature-item">ISO 27001 certified platform for data security</li>
              <li className="feature-item">Priority support with a dedicated success manager</li>
              <li className="feature-item">API access for deep integration with enterprise systems</li>
              <li className="feature-item">Onboarding, training, and extended support options</li>
            </ul>
            <Link to="/book-demo">  <button className="card-button">Contact Sales</button></Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Price;
