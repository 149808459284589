import "./Threed_Landing.css";
import { Examples } from "./Examples";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import React from "react";
import Thrred_Sofa from "./assets/final_sofa.mp4";
import { Variants } from "./Variants";
import icon1 from "./assets/1.png";
import icon2 from "./assets/2.png";
import icon3 from "./assets/3.png";
import icon4 from "./assets/4.png";
import icon5 from "./assets/5.png";
import icon6 from "./assets/6.png";

const Threed_Landing = () => {
  const featuresData = [
    {
      icon: icon1,
      title: "Enhanced customer experience",
      description:
        "Provides customers the opportunity to interact and experience products in an engaging manner.",
    },
    {
      icon: icon2,
      title: "Reduced returns due to 3D rendering",
      description:
        "Realistic visuals improve understanding, increasing satisfaction and reducing returns.",
    },
    {
      icon: icon3,
      title: "Higher sales through (online) channels",
      description:
        "Integrating 3D/AR boosts turnover by creating engaging shopping experiences.",
    },
    {
      icon: icon4,
      title: "Quicker buyer decisions",
      description:
        "Customers can quickly decide by selecting product options, colors, and accurate pricing.",
    },
    {
      icon: icon5,
      title: "Increased sales",
      description:
        "Configurator leads to higher order value as customers explore premium options.",
    },
    {
      icon: icon6,
      title: "Understanding user behavior",
      description:
        "Analytics tools provide insights to optimize products and customer experience.",
    },
  ];

  return (
    <>
      <section className="cta-section">
        <div className="cta-text">
          <h1 className="cta-heading">
            Enhancing Online Shopping with the XM3DView 3D Configurator
          </h1>
          <p className="cta-paragraph">
            The XM3DView 3D/AR Configurator transforms online shopping by
            allowing customers to interact with and customize products in
            real-time through 3D and Augmented Reality. This immersive
            experience enhances engagement and personalization, accessible from
            any device.
          </p>
          <Link to="/book-demo" className="cta-button-link">
            <button className="cta-button">Book a demo</button>
          </Link>
        </div>
      </section>

      <div className="threed-video-container">
        <video className="threed-video" autoPlay loop muted preload="auto">
          <source src={Thrred_Sofa} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <section className="features-grid">
        {featuresData.map((feature, index) => (
          <div className="feature-card" key={index}>
            <div className="feature-icon">
              <img src={feature.icon} alt={feature.title} />
            </div>
            <h3 className="feature-title">{feature.title}</h3>
            <p className="feature-description">{feature.description}</p>
          </div>
        ))}
      </section>

      <Variants />
      <Examples />
      <Footer />
    </>
  );
};

export default Threed_Landing;
