import './Example.css';

import { Link } from 'react-router-dom';
import React from 'react';
import demo1 from './assets/House.jpg';
import demo2 from './assets/fashion_one.jpg';
import demo3 from './assets/furniture_sofa.jpg';

// Card data defined outside the component to prevent redefinition on each render
const cardData = [
  {
    id: 1,
    imgSrc: demo1,
    industry: "Construction",
    description: "A 3D configurator streamlines design, visualizes projects, enhances customization, and improves decision-making.",
    buttonText: "View demo"
  },
  {
    id: 2,
    imgSrc: demo2,
    industry: "Fashion",
    description: "A 3D configurator enables custom designs, visualizes outfits, boosts engagement, and simplifies personalization.",
    buttonText: "View demo"
  },
  {
    id: 3,
    imgSrc: demo3,
    industry: "Furniture",
    description: "A 3D configurator customizes furniture designs, visualizes layouts, enhances shopping experiences, and simplifies decisions.",
    buttonText: "View demo"
  }
];

export function Examples() {
  return (
    <section className="configurator-examples">
      <h2 className="configurator-heading">Explore 3D Configurator Examples</h2>

      <div className="button-container">
        <Link to="/"  style={{textDecoration:'none'}} className="insight-button">Get Design Insights</Link>
      </div>

      <div className="cards-container">
        {cardData.map(({ id, imgSrc, industry, description, buttonText }) => (
          <div className="card" key={id}>
            <img src={imgSrc} alt={`${industry} industry demo`} className="card-image" />
            <div className="card-content">
              <p className="industry-text">{industry}</p>
              <h3 className="card-title">{industry}</h3>
              <p className="card-description">{description}</p>
              <Link to="/book-demo">
                <button className="view-demo-button" aria-label={`View ${industry} demo`}>{buttonText}</button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
