import "./Variant.css";
import { Link } from 'react-router-dom';
import image_1 from "./assets/sofa_1.png";
import image_2 from "./assets/sofa_2.png";
import image_3 from "./assets/sofa_3.png";

export function Variants() {
    const cardsData = [
        {
            id: 1,
            title: "Material Switcher",
            items: [
                "Assign multiple materials to any part",
                "Design your user interface and triggers",
            ],
            imgSrc: image_1
        },
        {
            id: 2,
            title: "Variants",
            items: [
                "Make the whole section of the object interchangeable",
                "Show and hide objects, lights",
            ],
            imgSrc: image_2
        },
        {
            id: 3,
            title: "Highlights",
            items: [
                "Make important parts stand out",
                "Triggered on click or mouse hover",
            ],
            imgSrc: image_3
        }
    ];

    return (
        <section className="variants-section">
            <header className="variants-header">
                <h2 className="variants-title">
                    Explore the available configuration options
                </h2>
                <p className="variants-description">
                    Learn about the configurator parts that don’t require code.
                </p>
            </header>

            <div className="variants-container">
                {cardsData.map(card => (
                    <div className="variant-card" key={card.id}>
                        <img src={card.imgSrc} alt={`${card.title} preview`} className="variant-image" />
                        <h4 className="variant-card-title">{card.title}</h4>
                        <ul className="variant-card-list">
                            {card.items.map((item, index) => (
                                <li className="variant-card-list-item" key={index}>
                                    {item}
                                </li>
                            ))}
                        </ul>
                        <Link 
                            to="https://myfirstdiamond.fr/ARsofa/" 
                            target="_blank" 
                            className="variant-card-link"
                        >
                            Explore {card.title}
                        </Link>
                    </div>
                ))}
            </div>
        </section>
    );
}
