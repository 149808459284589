import './Footer.css';

import { Link } from 'react-router-dom';
import React from 'react';
import logo from './xm.svg';

const Footer = () => {
  // Handle scroll to top when any footer link is clicked
  const handleLinkClick = () => {
    window.scrollTo(0, 0); // Scroll to top of the page
  };

  return (
    <footer className="footer-container">
      <div className="footer-columns">
        
        <div className="footer-column footer-column-logo">
          <div className="footer-logo">
            <Link to="/" style={{ textDecoration: 'none', color: '#949494' }} onClick={handleLinkClick}>
              <span className="logo-icon"><img className="f-logo" src={logo} alt='v-logo' /></span>
            </Link>
          </div>
          <ul className='footer-ul'>
            <Link to="/pricing" className='li-ft' onClick={handleLinkClick}><li className="footer-item-pricing one">Pricing</li></Link>
            <Link to="/" className='li-ft' onClick={handleLinkClick}><li className="footer-item-discover one">Solutions</li></Link>
            <Link to="https://vara3dconfigurator.com/" target='_new' className='li-ft' onClick={handleLinkClick}><li className="footer-item-security one">Projects</li></Link>
          </ul>
        </div>

        <div className="footer-column footer-column-explore">
          <h4 className="footer-title-explore">Solutions</h4>
          <ul className='footer-ul'>
            <Link to="/ar" className='li-fit' style={{ textDecoration: 'none', color: '#949494' }} onClick={handleLinkClick}>
              <li className="footer-item-ar">Augmented Reality</li>
            </Link>
            <Link to="/3d" className='li-fit' style={{ textDecoration: 'none', color: '#949494' }} onClick={handleLinkClick}>
              <li className="footer-item-configurator">3D Configurator</li>
            </Link>
          </ul>
        </div>

        <div className="footer-column footer-column-industries">
          <h4 className="footer-title-industries">Industries</h4>
          <ul className='footer-ul'>
            <Link to="/book-demo" style={{ textDecoration: 'none', color: '#949494' }} onClick={handleLinkClick}><li className="footer-item-manufacturing">Manufacturing</li></Link>
            <Link to="/book-demo" style={{ textDecoration: 'none', color: '#949494' }} onClick={handleLinkClick}><li className="footer-item-furniture">Furniture</li></Link>
            <Link to="/book-demo" style={{ textDecoration: 'none', color: '#949494' }} onClick={handleLinkClick}><li className="footer-item-fashion">Fashion</li></Link>
            <Link to="/book-demo" style={{ textDecoration: 'none', color: '#949494' }} onClick={handleLinkClick}><li className="footer-item-electronics">Electronics</li></Link>
            <Link to="/book-demo" style={{ textDecoration: 'none', color: '#949494' }} onClick={handleLinkClick}><li className="footer-item-consumer">Construction</li></Link>
            <Link to="/book-demo" style={{ textDecoration: 'none', color: '#949494' }} onClick={handleLinkClick}><li className="footer-item-agencies">Agencies</li></Link>
          </ul>
        </div>

        <div className="footer-column footer-column-resources">
          <h4 className="footer-title-resources">Resources</h4>
          <ul className='footer-ul'>
            <li className="footer-item-docs"></li>
            <Link to="/faq" style={{ textDecoration: 'none', color: '#949494' }} onClick={handleLinkClick}><li className="footer-item-discord">FAQ</li></Link>
            <Link to="/faq" style={{ textDecoration: 'none', color: '#949494' }} onClick={handleLinkClick}><li className="footer-item-blogs">Blogs</li></Link>
            <Link to="https://myfirstdiamond.fr/ARsofa/" target='_new' style={{ textDecoration: 'none', color: '#949494' }} onClick={handleLinkClick}><li className="footer-item-examples">Examples</li></Link>
          </ul>
        </div>

        <div className="footer-column footer-column-social">
          <h4 className="footer-title-social">Social</h4>
          <ul className='footer-ul'>
            <Link to="https://www.linkedin.com/company/xm3dview/" target='_new' style={{ textDecoration: 'none', color: '#949494' }} onClick={handleLinkClick}>
              <li className="footer-item-linkedin">LinkedIn</li>
            </Link>
            <Link to="https://www.instagram.com/xm_3dview?igsh=NmhhYWg0eHZlbmpr" target='_new' style={{ textDecoration: 'none', color: '#949494' }} onClick={handleLinkClick}>
              <li className="footer-item-instagram">Instagram</li>
            </Link>
            <Link to="https://www.facebook.com/profile.php?id=61564835911138" target='_new' style={{ textDecoration: 'none', color: '#949494' }} onClick={handleLinkClick}>
              <li className="footer-item-twitter">Facebook</li>
            </Link>
          </ul>
        </div>

      </div>

      <div className="footer-bottom">
        <p className="footer-copyright">
          Developed by Xstream Minds Pvt Ltd  &  © 2024 XM3Dview All rights reserved
        </p>
      </div>
    </footer>
  );
};

export default Footer;
